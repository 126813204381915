import { useStaticQuery, graphql } from "gatsby"

const ALL_AIRTABLE_ME_FIELDS_QUERY = graphql`
  query {
    allAirtableallMedEnsureFormFields(
      filter: {
        data: {
          Show_On_Website: { eq: true }
          Product: { eq: "MedEnsure Request" }
        }
      }
    ) {
      nodes {
        data {
          Name
          Type
          Initial_Values
          Field_Names
          Module
          Show_On_Website
          Zendesk_Key
          Show_On_Zendesk
          Zendesk_Order
        }
      }
    }
  }
`

export const useAirtableMEFormFields = () => {
  const data = useStaticQuery(ALL_AIRTABLE_ME_FIELDS_QUERY)

  return data
}
