import React from "react"
import classNames from "classnames"
import * as styles from "./utils/elements.module.scss"
import maxibear from "../../../static/images/maxibear.svg"

const SlidingModal = (props) => {
  return (
    <div
      className={classNames("modal is-hidden-tablet", {
        "is-active": props.isModalActive,
      })}
    >
      <div
        className={`modal-background has-background-${props.modalBackground?.color} has-opacity-${props.modalBackground?.opacity}`}
        onClick={() => {
          if (props.closeOnClickOutside) props.closeModal()
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter" && props.closeOnClickOutside) {
            props.closeModal()
          }
        }}
        role="button"
        tabIndex={0}
        aria-label="Modal Background"
      ></div>
      <SlidingModalCardBody {...props} />
    </div>
  )
}

const SlidingModalCardBody = (props) => {
  return (
    <div
      className={classNames(
        "p-0",
        styles["slidingModal__card"],
        { [styles["slidingModal__cardIsActive"]]: props.isModalActive },
        { [styles["slidingModal__cardIsClosed"]]: props.isModalClosed }
      )}
    >
      {props.heading && (
        <div
          className={classNames(
            props.modalHeaderClass,
            {
              "has-background-white": !props.modalHeaderClass,
            },
            {
              [styles["slidingModal__cardHeaderFullheight"]]:
                props.isFullheight,
            }
          )}
        >
          <p className={classNames("modal-card-title", props.modalHeaderClass)}>
            {props.heading}
          </p>
        </div>
      )}
      <div
        className={classNames(
          "has-text-centered",
          styles["slidingModal__cardBody"],
          { [styles["maxicareBackground"]]: props.hasBackground }
        )}
      >
        {/* {props.hasBackgroundAddons && (
          <div className={classNames(styles["maxibearWrapper"])}>
            <img
              className={classNames(styles["maxibear"])}
              src={maxibear}
              alt="Maxibear"
            />
          </div>
        )} */}
        <div
          className={classNames(styles["slidingModal__closingBar"])}
          onClick={props.closeModal}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              props.closeModal()
            }
          }}
          role="button"
          tabIndex={0}
          aria-label="Close sliding modal"
        ></div>
        {props.children}
      </div>
    </div>
  )
}

export default SlidingModal
