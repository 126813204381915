import React, { useReducer } from "react"

import { MedEnsureReducer } from "./MedEnsureReducer"
import { useAirtableMEFormFields } from "./hooks/useAirtableMEFormFields"

import { generateInitialValues } from "../../../services/context"

let { flattenNode } = require("../../../services/arrays")

const MedEnsureContext = React.createContext()

const MedEnsureProvider = ({ children }) => {
  let formFields = useAirtableMEFormFields()
  formFields = flattenNode(formFields?.allAirtableallMedEnsureFormFields)
  let [medEnsureState, medEnsureDispatch] = useReducer(MedEnsureReducer, {
    ...generateInitialValues({ fields: formFields }),
    documents: [],
    medicines: [],
    consentAndAuthorization: [],
    initialState: {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      medicines: [],
      consentAndAuthorization: [],
    },
  })

  return (
    <MedEnsureContext.Provider value={{ medEnsureState, medEnsureDispatch }}>
      {children}
    </MedEnsureContext.Provider>
  )
}

export { MedEnsureContext, MedEnsureProvider }
