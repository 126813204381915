// extracted by mini-css-extract-plugin
export var cart__containerIsHiddenTablet = "layout-module--cart__container--is-hidden-tablet--e1775";
export var disclaimer = "layout-module--disclaimer--d5fbe";
export var dots = "layout-module--dots--f49fb";
export var fade = "layout-module--fade--4a057";
export var fadeAndPeek = "layout-module--fade-and-peek--cd485";
export var fadeAndPeekAnimation = "layout-module--fadeAndPeekAnimation--11c76";
export var fadeFromRight = "layout-module--fadeFromRight--e2077";
export var modalSlideIn = "layout-module--modal-slide-in--d289a";
export var modalSlideOut = "layout-module--modal-slide-out--72fd9";
export var navbar = "layout-module--navbar--b038c";
export var navbar__alignCenter = "layout-module--navbar__align-center--8ae4d";
export var navbar__backgroundIsActive = "layout-module--navbar__background--is-active--3f2b6";
export var navbar__badge = "layout-module--navbar__badge--34e45";
export var navbar__brand = "layout-module--navbar__brand--6fba3";
export var navbar__brandLogos = "layout-module--navbar__brand--logos--a4834";
export var navbar__burgerContainer = "layout-module--navbar__burger-container--b458a";
export var navbar__cart = "layout-module--navbar__cart--ee95c";
export var navbar__menu = "layout-module--navbar__menu--df559";
export var navbar__menuItem = "layout-module--navbar__menu-item--17bde";
export var navbar__personalDetailsLink = "layout-module--navbar__personal-details--link--161d8";
export var navbar__right = "layout-module--navbar__right--e4894";
export var notification = "layout-module--notification--fd960";
export var orangePolygon = "layout-module--orange-polygon--961ea";
export var progressBar = "layout-module--progress-bar--37445";
export var signInButton = "layout-module--sign-in-button--972d6";
export var slideUpAndDown = "layout-module--slideUpAndDown--2293f";
export var tealPolygon = "layout-module--teal-polygon--2bda3";
export var toast = "layout-module--toast--46955";
export var toast__message = "layout-module--toast__message--7bf46";